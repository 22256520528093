/* layout.css Style */
.upload-drop-zone {
  height: 200px;
  border-width: 2px;
  text-align: center;
  margin-bottom: 20px;
}

/* skin.css Style*/
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}

input[type="file"] {
  opacity: 0;
}


